const spriteMap = {
  key1: [0, 157.80045351473922],
  key2: [1250, 157.80045351473925],
  key3: [2500, 157.80045351473905],
  shutter: [3750, 261.224489795918],
  dialing: [5000, 2115.9183673469392],
  invalid: [8250, 1602.3582766439902],
};

export default spriteMap;
